body {
  height: 100%;
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-x pan-y;
}

html,
body {
  width: 100%;
  overflow: auto;
}

/* html {
  height: 100vh;
} */

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div:has(> div.paypal-buttons) {
  width: 100%;
}

.MuiContainer-root {
  position: relative;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  /*transform: scale(1.1);*/
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.fade-enter {
  opacity: 0;
  transform: translate(0, 100hv);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 350ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, -100vh);

  transition: opacity 350ms ease-out, transform 300ms ease;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
.item-exit-zoom-out-active {
  transform: scale(0.3) translateY(-100%);
  transition: scale 450ms ease-out, transform 450ms ease-out;
}

/*.fade-enter{*/
/*  opacity: 0;*/
/*}*/
/*.fade-exit{*/
/*  opacity: 1;*
/*}*/
/*.fade-enter-active{*/
/*  opacity: 1;*/
/*}*/
/*.fade-exit-active{*/
/*  opacity: 0;*/
/*}*/
/*.fade-enter-active,*/
/*.fade-exit-active{*/
/*  opacity: 0;*/
/*  transform: translate(0, -100vh);*/

/*  transition: opacity 350ms ease-out, transform 300ms ease;*/
/*}*/
